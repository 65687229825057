(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:Matches
   *
   * @description
   *
   */
  angular
    .module('neo.services')
    .factory('Matches', Matches);

  function Matches($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/matches/:id', {id: '@_id'});
  }
}());
